import React, { useEffect, useState, useMemo } from 'react';
import { Typography, Box, Paper, CircularProgress, Breadcrumbs, Pagination, Button } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import theme from '../../theme/theme';
import { getStaffByWorkingTeamSum } from '../../service/team-service';

const TeamWorkingPage = () => {
  const { workingTeamId } = useParams();
  const [staffList, setStaffList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  // Pagination calculation
  const totalPages = useMemo(() => Math.ceil(totalCount / itemsPerPage), [totalCount, itemsPerPage]);

  const fetchStaffData = async (currentPage) => {
    setIsLoading(true);
    setError(false);
    const offset = (currentPage - 1) * itemsPerPage;

    try {
      const staffData = await getStaffByWorkingTeamSum(workingTeamId, offset, itemsPerPage);
      setStaffList(staffData?.data || []);
      setTotalCount(staffData?.totalCount || 0);
    } catch (error) {
      console.error('Error fetching staff:', error);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (workingTeamId) {
      fetchStaffData(page);
    }
  }, [workingTeamId, page]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/home" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
          หน้าหลัก
        </Link>
        <Typography color="text.primary">ลูกทีมทั้งหมด</Typography>
      </Breadcrumbs>

      <Typography
        variant="h5"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 2 }}
      >
        ลูกทีมทั้งหมด
      </Typography>

      {/* Status Summary */}
      <Box display="flex" justifyContent="space-around" alignItems="center" sx={{ my: 2 }}>
        <Typography align="center" gutterBottom sx={{ color: theme.palette.primary.main }}>
          ทั้งหมด: {totalCount}
        </Typography>
        {/* These can display actual counts if the data includes these */}
        <Typography align="center" gutterBottom sx={{ color: theme.palette.primary.main }}>
          คุยแล้ว:
        </Typography>
        <Typography align="center" gutterBottom sx={{ color: theme.palette.primary.main }}>
          ยังไม่คุย:
        </Typography>
      </Box>

      {/* Main Content */}
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress color="primary" />
        </Box>
      ) : error ? (
        <Typography color="error" align="center" sx={{ mt: 4 }}>
          เกิดข้อผิดพลาดในการโหลดข้อมูล
        </Typography>
      ) : staffList.length === 0 ? (
        <Typography align="center" color="text.secondary" sx={{ mt: 4 }}>
          ไม่มีข้อมูลลูกทีม
        </Typography>
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* Staff List */}
            {staffList.map((staff) => (
              <Link
                to={`/team-working-detail/${workingTeamId}/${staff?.id}`}
                key={staff?.id}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    padding: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '10px',
                    boxShadow: 2,
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                  }}
                >
                  <Typography sx={{ flexGrow: 1, fontWeight: 600 }}>{staff?.displayName}</Typography>
                  <Typography sx={{ fontWeight: 500 }}>
                    {staff?.__workSummary.doneCount}/{staff?.__workSummary.totalCount}
                  </Typography>
                </Paper>
              </Link>
            ))}
          </Box>

          {/* Pagination */}
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}
            />
          )}
        </>
      )}
      <Button
        variant="outlined"
        fullWidth
        onClick={() => navigate('/home')}
        sx={{
          mt: 3,
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          fontWeight: 600,
          '&:hover': {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
          },
        }}
      >
        ย้อนกลับ
      </Button>
    </Box>
  );
};

export default TeamWorkingPage;
