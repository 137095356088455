import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Paper,
  Button,
  CircularProgress,
  Breadcrumbs,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '../../theme/theme';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getStaffById } from '../../service/team-service';
import { getGifts, getZoneByIdWithSurveySummary } from '../../service/contact-service';

const SummaryPage = () => {
  const { staffId } = useParams();
  const navigate = useNavigate();
  const [staffData, setStaffData] = useState(null);
  const [zoneData, setZoneData] = useState(null);
  const [giftData, setGiftData] = useState([]); // Store gift data
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const staff = await getStaffById(staffId);
        setStaffData(staff);

        // Fetch zone data if available
        const zoneId = staff?.data.zoneMemberships?.[0]?.zone?.id;
        if (zoneId) {
          fetchZoneData(zoneId);
        }

        // Fetch gift data
        const giftResponse = await getGifts();
        setGiftData(giftResponse?.data || []);

        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch initial data:', error);
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [staffId]);

  const fetchZoneData = async (zoneId) => {
    try {
      const data = await getZoneByIdWithSurveySummary(zoneId);
      setZoneData(data);
    } catch (error) {
      console.error('Failed to fetch zone data:', error);
    }
  };

  const getGiftIcon = (iconName) => {
    switch (iconName) {
      case 'apple':
        return '🍎';
      case 'banana':
        return '🍌';
      case 'orange':
        return '🍊';
      case 'grape':
        return '🍇';
      default:
        return '🎁';
    }
  };

  const getSurveyStatus = (result) => {
    const { lowCount, mediumCount, highCount } = result || {};
    if (highCount >= mediumCount && highCount >= lowCount) {
      return 'HIGH';
    } else if (mediumCount >= lowCount) {
      return 'medium';
    } else {
      return 'improve';
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'HIGH':
        return theme.palette.component.good;
      case 'improve':
        return theme.palette.component.improve;
      case 'medium':
        return theme.palette.component.medium;
      default:
        return theme.palette.component.default;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        padding: 2,
      }}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/home" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
          หน้าหลัก
        </Link>

        <Typography color="text.primary">สรุปงาน</Typography>
      </Breadcrumbs>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography
            variant="h5"
            fontWeight={700}
            align="center"
            gutterBottom
            sx={{ color: theme.palette.primary.main, my: 2 }}
          >
            สรุปงาน
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {zoneData ? (
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  boxShadow: 3,
                  borderRadius: '10px',
                }}
              >
                {staffData?.data.zoneMemberships?.map((section, sectionIndex) => {
                  const surveyStatus = getSurveyStatus(zoneData?.data?.__surveySummary?.result);
                  const statusColor = getStatusColor(surveyStatus);

                  return (
                    <Accordion
                      key={sectionIndex}
                      sx={{
                        borderRadius: '10px !important',
                        marginBottom: 1,
                        '&:before': { display: 'none' },
                        backgroundColor: statusColor,
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" fontWeight={700}>
                          พื้นที่: {section?.zone?.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: 'white',
                          color: theme.palette.text.secondary,
                          borderRadius: '0 0 10px 10px',
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                          <Typography>จำนวนคนในพื้นที่:</Typography>
                          <Typography>{zoneData?.data?.customers?.length}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                          <Typography>จำนวนคนไปหาแล้ว:</Typography>
                          <Typography>{zoneData?.data?.__surveySummary?.doneCount}</Typography>
                        </Box>
                        <Box sx={{ p: 2 }}>
                          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                            <Typography color="error">ควรปรับปรุง:</Typography>
                            <Typography color="error">{zoneData?.data?.__surveySummary?.result?.lowCount}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                            <Typography color="warning">ปานกลาง:</Typography>
                            <Typography color="warning">
                              {zoneData?.data?.__surveySummary?.result?.mediumCount}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                            <Typography color="success">ดี:</Typography>
                            <Typography color="success">
                              {zoneData?.data?.__surveySummary?.result?.highCount}
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2 }}>
                          {/* <Typography variant="subtitle1" fontWeight={700}>
                            รวมของขวัญทั้งหมดในพื้นที่:
                          </Typography> */}
                          {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            {giftData.map((gift) => {
                              const giftCount = zoneData?.data?.customers?.reduce((count, customer) => {
                                const giftItem = customer.giftMeta?.find((meta) => meta.id === gift.id);
                                return count + (giftItem ? giftItem.count : 0);
                              }, 0);
                              return (
                                <Typography key={gift.id} sx={{ fontWeight: 500 }}>
                                  {getGiftIcon(gift.iconName)} {giftCount}
                                </Typography>
                              );
                            })}
                          </Box> */}
                          {/* {zoneData?.data?.customers?.map((customer) => (
                            <Box
                              key={customer.id}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                backgroundColor: '#f9f9f9',
                                p: 1,
                                borderRadius: '5px',
                              }}
                            >
                              <Typography variant="body1">{customer.displayName}</Typography>
                              <Typography variant="body1" fontWeight={500} sx={{ color: theme.palette.primary.main }}>
                                {customer.giftTotalValue.toLocaleString()} บาท
                              </Typography>
                            </Box>
                          ))} */}
                          <Typography
                            variant="body"
                            fontWeight={700}
                            align="center"
                            sx={{ color: theme.palette.success.main }}
                          >
                            มูลค่าของขวัญรวม: {zoneData?.data?.__surveySummary?.giftTotalValue.toLocaleString()} บาท
                          </Typography>
                        </Box>

                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={() => navigate(`/all-contact-summary-page/${staffId}/${zoneData?.data.id}`)}
                          sx={{ mt: 2 }}
                        >
                          แสดงรายชือ
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Paper>
            ) : (
              <Paper elevation={3} sx={{ padding: 2, boxShadow: 3, borderRadius: '10px' }}>
                <Typography variant="h6" color="text.secondary" align="center">
                  ไม่มีข้อมูลพื้นที่สำหรับทีมนี้
                </Typography>
              </Paper>
            )}
          </Box>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => navigate('/home')}
            sx={{
              mt: 3,
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
              fontWeight: 600,
              '&:hover': {
                borderColor: theme.palette.primary.dark,
                color: theme.palette.primary.dark,
              },
            }}
          >
            ย้อนกลับ
          </Button>
        </>
      )}
    </Box>
  );
};

export default SummaryPage;
