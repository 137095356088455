import React, { useState, useEffect, useCallback } from 'react';
import {
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
  useMediaQuery,
  useTheme,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import { Phone, Visibility, VisibilityOff } from '@mui/icons-material';
import { getConfig, login } from '../service/auth-service';
import { useAuth } from '../context/auth-context';

const LoginPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const auth = useAuth();

  const [team, setTeam] = useState('');
  const [pin, setPin] = useState('');
  const [showPin, setShowPin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [gameCodes, setGameCodes] = useState([]);

  // Fetch game codes on mount
  useEffect(() => {
    const fetchGameCodes = async () => {
      try {
        const { gameCodes } = await getConfig();
        setGameCodes(gameCodes || []);
      } catch (err) {
        console.error('Error fetching game codes:', err);
        // setError('Failed to load game codes. Please try again later.');
      }
    };
    fetchGameCodes();
  }, []);

  // Reset error when inputs change
  useEffect(() => {
    setError('');
  }, [team, pin]);

  // Toggle PIN visibility
  const togglePinVisibility = () => setShowPin((prev) => !prev);

  // Handle team and PIN changes
  const handleInputChange = useCallback(
    (setter) => (event) => {
      setter(event.target.value);
    },
    []
  );

  const checkPinWithGameCode = async () => {
    if (gameCodes.includes(pin)) {
      window.location.href = 'https://albertgabdullin.github.io/puzzle15/';
      return true;
    }
    return false;
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (await checkPinWithGameCode()) {
      setLoading(false);
      return;
    }

    try {
      const loginResponse = await login(team, pin);
      auth.setAccessToken(loginResponse.access_token);
      navigate('/home');
    } catch (err) {
      console.error('Login error:', err);
      setError('ไม่สามารถเข้าสู่ระบบได้ กรุณาตรวจสอบชื่อผู้ใช้หรือรหัสผ่าน');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        background: `linear-gradient(to bottom, #001f3f, #005f7f)`,
        padding: '16px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '400px',
          textAlign: 'center',
          borderRadius: '16px',
          overflow: 'hidden',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.8)',
          backgroundColor: '#1A1A1A',
        }}
      >
        <Box
          sx={{
            padding: '24px',
            background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
            color: 'white',
            textAlign: 'center',
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0,0,0,0.8)' }}>
            My Game
          </Typography>
        </Box>

        <Paper
          sx={{
            padding: '24px',
            backgroundColor: '#2a2a2a',
            borderRadius: '0 0 16px 16px',
            color: 'white',
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: `${theme.palette.primary.main}`, mb: 3 }}>
            เข้าสู่ระบบ
          </Typography>

          {loading && (
            <Box display="flex" justifyContent="center" my={2}>
              <CircularProgress color="primary" />
            </Box>
          )}

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box
            component="form"
            onSubmit={handleLogin}
            sx={{ display: 'flex', flexDirection: 'column', gap: 3, textAlign: 'left' }}
          >
            <Box>
              <Typography sx={{ color: 'lightgray' }}>USERNAME:</Typography>
              <TextField
                name="team"
                variant="outlined"
                fullWidth
                value={team}
                onChange={handleInputChange(setTeam)}
                error={Boolean(error && !team)}
                helperText={error && !team ? 'กรุณากรอก Username ให้ถูกต้อง' : ''}
                sx={{
                  backgroundColor: '#333333',
                  borderRadius: '8px',
                  input: { color: 'white' },
                }}
                InputProps={{
                  startAdornment: <Phone sx={{ color: `${theme.palette.primary.light}`, mr: 1 }} />,
                }}
              />
            </Box>

            <Box>
              <Typography sx={{ color: 'lightgray' }}>PIN:</Typography>
              <TextField
                name="pin"
                type={showPin ? 'number' : 'password'}
                variant="outlined"
                fullWidth
                value={pin}
                onChange={handleInputChange(setPin)}
                error={Boolean(error && pin.length !== 6)}
                helperText={error && pin.length !== 6 ? 'PIN ต้องเป็นตัวเลข 6 หลัก' : ''}
                sx={{
                  backgroundColor: '#333333',
                  borderRadius: '8px',
                  input: { color: 'white' },
                }}
                InputProps={{
                  startAdornment: <LockIcon sx={{ color: `${theme.palette.primary.light}`, mr: 1 }} />,
                  endAdornment: (
                    <IconButton onClick={togglePinVisibility} edge="end">
                      {showPin ? <VisibilityOff sx={{ color: 'white' }} /> : <Visibility sx={{ color: 'white' }} />}
                    </IconButton>
                  ),
                }}
              />
            </Box>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={loading}
              sx={{
                mt: 2,
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                fontSize: '16px',
                fontWeight: 'bold',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
                '&:hover': {
                  background: `linear-gradient(45deg, ${theme.palette.secondary.dark}, ${theme.palette.secondary.main})`,
                },
              }}
            >
              ยืนยัน
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default LoginPage;
