import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Box, Paper, Breadcrumbs, Button, CircularProgress, Pagination } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { getGifts, getZonesBySurveyStatus } from '../../service/contact-service';

const ContactedListPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // State for pagination, data, loading, and error
  const [contactedList, setContactedList] = useState([]);
  const [gifts, setGifts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const limit = 10; // number of items per page
  const [unmanagedCustomersList, setUnmanagedCustomersList] = useState({});
  const totalPages = useMemo(() => Math.ceil(totalCount / limit), [totalCount, limit]);

  const fetchData = async (offset) => {
    setLoading(true);
    setError(null);

    try {
      const zonesData = await getZonesBySurveyStatus(true, offset, limit);
      const giftsData = await getGifts();

      console.log(zonesData, 'zonesData');
      if (zonesData && zonesData.data) {
        setContactedList(zonesData.data);
        setUnmanagedCustomersList(zonesData?.additionalInfo?.unmanagedCustomers);
        console.log();

        setTotalCount(zonesData.totalCount || 0);
      }

      if (giftsData && giftsData.data) {
        setGifts(giftsData.data);
      }
    } catch (err) {
      setError('Failed to fetch data.');
    }

    setLoading(false);
  };

  useEffect(() => {
    const offset = (page - 1) * limit;
    fetchData(offset);
  }, [page, limit]); // Adding limit for clarity

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  const handlePageChange = (_, value) => {
    setPage(value);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'HIGH':
        return theme.palette.component.good;
      case 'LOW':
        return theme.palette.component.improve;
      case 'MEDIUM':
        return theme.palette.component.medium;
      default:
        return theme.palette.component.default;
    }
  };

  const getGiftIcon = (iconName) => {
    switch (iconName) {
      case 'apple':
        return '🍎';
      case 'banana':
        return '🍌';
      case 'orange':
        return '🍊';
      case 'grape':
        return '🍇';
      default:
        return '🎁';
    }
  };

  const findGiftIconById = (giftId) => {
    const gift = gifts.find((g) => g.id === parseInt(giftId)); // Parse giftId to ensure type consistency
    return gift ? getGiftIcon(gift.iconName) : '🎁';
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '100%', padding: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/home" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
          หน้าหลัก
        </Link>
        <Typography color="text.primary">รายชื่อที่คุยแล้ว</Typography>
      </Breadcrumbs>

      <Typography
        variant="h5"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 2 }}
      >
        รายชื่อที่คุยแล้ว
      </Typography>

      {/* Loading, Error, or Contacted List */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress color="primary" />
        </Box>
      ) : error ? (
        <Typography color="error" align="center">
          {error}
        </Typography>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {unmanagedCustomersList?.surveyedCustomers?.length > 0 && (
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                borderRadius: '10px',
                backgroundColor: 'white',
              }}
            >
              <Typography variant="body1" fontWeight={700} fontSize={16} sx={{ mb: 1 }}>
                พื้นที่: ไม่กำหนดพื้นที่
              </Typography>
              {unmanagedCustomersList?.surveyedCustomers?.length > 0 ? (
                unmanagedCustomersList?.surveyedCustomers?.map((person, personIndex) => (
                  <Link
                    to={`/contacted-edit/${person.id}`}
                    key={personIndex}
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 2,
                        borderRadius: '10px',
                        boxShadow: 2,
                        mb: 1,
                        backgroundColor: getStatusColor(person.surveyResult),
                      }}
                    >
                      <Typography sx={{ flexGrow: 1, fontWeight: 600 }} color="white">
                        {person.displayName}
                      </Typography>

                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {person.giftMeta?.map((gift, idx) => (
                          <Typography key={idx} sx={{ fontWeight: 500 }}>
                            {findGiftIconById(gift.id)} {gift.count}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </Link>
                ))
              ) : (
                <Typography color="text.secondary" sx={{ fontStyle: 'italic', textAlign: 'center', mt: 2 }}>
                  ไม่มีข้อมูลลูกค้าที่ไม่ได้คุยในพื้นที่นี้
                </Typography>
              )}
            </Paper>
          )}

          {contactedList.map((section, index) => (
            <Paper key={index} elevation={3} sx={{ padding: 2, boxShadow: 3, borderRadius: '20px' }}>
              <Typography variant="body1" fontWeight={700} fontSize={16} sx={{ mb: 1 }}>
                พื้นที่: {section?.name}
              </Typography>

              {section.customers?.map((person, personIndex) => (
                <Link
                  to={`/contacted-edit/${person.id}`}
                  key={personIndex}
                  style={{ color: 'black', textDecoration: 'none' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: 2,
                      borderRadius: '10px',
                      boxShadow: 2,
                      mb: 1,
                      backgroundColor: getStatusColor(person.surveyResult),
                    }}
                  >
                    <Typography sx={{ flexGrow: 1, fontWeight: 600 }} color="white">
                      {person.displayName}
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      {person.giftMeta?.map((gift, idx) => (
                        <Typography key={idx} sx={{ fontWeight: 500 }}>
                          {findGiftIconById(gift.id)} {gift.count}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Link>
              ))}
            </Paper>
          ))}
        </Box>
      )}

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            siblingCount={1}
            boundaryCount={1}
            showFirstButton
            showLastButton
            sx={{
              '& .Mui-selected': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
              },
            }}
          />
        </Box>
      )}

      {/* Full-width Back Button */}
      <Button
        variant="outlined"
        fullWidth
        onClick={() => navigate('/home')}
        sx={{
          mt: 3,
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          fontWeight: 600,
          '&:hover': {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
          },
        }}
      >
        ย้อนกลับ
      </Button>
    </Box>
  );
};

export default ContactedListPage;
