import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Paper,
  Breadcrumbs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Pagination,
  Button,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import theme from '../../theme/theme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getStaffByWorkingTeam } from '../../service/team-service';

const MyTeamPage = () => {
  const { workingTeamId } = useParams();
  const [staffList, setStaffList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [totalItems, setTotalItems] = useState(0); // Total staff count
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStaffData = async () => {
      setIsLoading(true);
      setError(false);
      try {
        const offset = (currentPage - 1) * itemsPerPage;
        const staffData = await getStaffByWorkingTeam(workingTeamId, offset, itemsPerPage);
        setStaffList(staffData?.data || []);
        setTotalItems(staffData?.totalCount || 0);
      } catch (error) {
        console.error('Error fetching staff:', error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (workingTeamId) {
      fetchStaffData();
    }
  }, [workingTeamId, currentPage, itemsPerPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        padding: 2,
      }}
    >
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/home" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
          หน้าหลัก
        </Link>
        <Typography color="text.primary">ลูกทีมทั้งหมด</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography
        variant="h5"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 2 }}
      >
        ลูกทีมทั้งหมด
      </Typography>

      {/* Main Content */}
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress color="primary" />
        </Box>
      ) : error ? (
        <Typography color="error" align="center" sx={{ mt: 4 }}>
          เกิดข้อผิดพลาดในการโหลดข้อมูล
        </Typography>
      ) : staffList.length === 0 ? (
        <Typography align="center" color="text.secondary" sx={{ mt: 4 }}>
          ไม่มีข้อมูลลูกทีม
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {/* Staff List */}
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              boxShadow: 3,
              borderRadius: '20px',
            }}
          >
            {staffList.map((staff, index) => (
              <Accordion
                key={index}
                sx={{
                  borderRadius: '10px !important',
                  mb: 1,
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.text.primary,
                  '&:before': {
                    display: 'none',
                  },
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                  <Typography sx={{ fontWeight: 600 }} color="white">
                    {staff?.displayName}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: 'white',
                    color: theme.palette.text.secondary,
                    borderRadius: '0 0 10px 10px',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Typography fontWeight={600}>เบอร์โทร:</Typography>
                      <Typography>{staff?.username ? staff?.username : '-'}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Typography fontWeight={600}>Pin:</Typography>
                      <Typography>{staff?.__password ? staff?.__password : '-'}</Typography>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Paper>

          {/* Pagination */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            {Math.ceil(totalItems / itemsPerPage) > 1 && (
              <Pagination
                count={Math.ceil(totalItems / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            )}
          </Box>
        </Box>
      )}
      <Button
        variant="outlined"
        fullWidth
        onClick={() => navigate('/home')}
        sx={{
          mt: 3,
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          fontWeight: 600,
          '&:hover': {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
          },
        }}
      >
        ย้อนกลับ
      </Button>
    </Box>
  );
};

export default MyTeamPage;
