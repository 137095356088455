import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Button, Box, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Tesseract from 'tesseract.js';
import { extractCustomerFromImage } from '../../../service/customer-service';

const OCRScanPage = ({ activeStep, setActiveStep, setPhotoData, setFormData }) => {
  const videoRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          setIsCameraActive(true);
        }
      } catch (err) {
        console.error('Error accessing the camera', err);
      }
    };

    if (activeStep === 0) {
      startCamera();
    }

    return () => {
      stopCamera(); // Ensure the camera is stopped when the component unmounts or the activeStep changes
    };
  }, [activeStep]);

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop()); // Stop all active tracks
      setIsCameraActive(false);
    }
  };

  const handleCapture = useCallback(async () => {
    if (!videoRef.current) return;

    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    const photoDataURL = canvas.toDataURL('image/png');

    setLoading(true);

    try {
      const blob = await (await fetch(photoDataURL)).blob();
      const file = new File([blob], 'capture.png', { type: 'image/png' });

      const uploadResponse = await extractCustomerFromImage(file);
      console.log(uploadResponse, 'uploadResponse');

      const fileId = uploadResponse?.id;

      if (uploadResponse?.statusCode === -1) {
        alert('ไม่สามารถอ่านข้อมูลจากภาพได้ กรุณาถ่ายรูปใหม่อีกครั้ง');
        setLoading(false);
        return;
      }

      const formData = uploadResponse?.data;

      setLoading(false);
      setFormData({
        preName: formData?.title,
        name: formData?.firstName,
        surname: formData?.lastName,
        id: formData?.nationalId,
        address: formData?.address,
        province: formData?.zoneL1,
        district: formData?.zoneL2,
        subDistrict: formData?.zoneL3,
        house: formData?.zoneL4,
      });

      if (fileId) {
        setPhotoData(fileId);
      } else {
        setPhotoData(photoDataURL);
      }
      stopCamera();
      setActiveStep((prev) => prev + 1);
    } catch (error) {
      console.error('Error during upload or extraction:', error);
      alert('เกิดข้อผิดพลาดระหว่างการประมวลผล กรุณาลองใหม่');
      setLoading(false);
    }
  }, [setPhotoData, setActiveStep, setFormData]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3,
        backgroundColor: '#f4f6f8',
        borderRadius: 3,
        boxShadow: 3,
        maxWidth: '500px',
        mx: 'auto',
        mt: 5,
      }}
    >
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        กรุณาถ่ายรูปบัตรประชาชน
      </Typography>

      {/* Camera Feed */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          maxWidth: '400px',
          aspectRatio: '1.59', // Matches the aspect ratio of the Thai ID card
          margin: 'auto',
        }}
      >
        {isCameraActive && (
          <>
            <video
              ref={videoRef}
              autoPlay
              playsInline
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '8px',
                objectFit: 'cover',
              }}
            />

            {/* Overlay Frame */}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
                backgroundImage: 'url(../../assets/images/id-card-frame.png)', // Replace with your frame image path
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
          </>
        )}
      </Box>
      <Button variant="contained" color="primary" onClick={handleCapture} disabled={loading} sx={{ mb: 2 }}>
        {loading ? <CircularProgress size={24} /> : 'ถ่ายรูป'}
      </Button>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 3 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            stopCamera();
            setActiveStep(6);
          }}
          fullWidth
          sx={{ mr: 1 }}
        >
          ค้นหารายชื่อ
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            stopCamera();
            setActiveStep((prev) => prev + 1);
          }}
          fullWidth
        >
          เพิ่มรายชื่อเอง
        </Button>
      </Box>
      <Button
        variant="outlined"
        type="button"
        fullWidth
        onClick={() => {
          stopCamera();
          navigate(-1);
        }}
        sx={{
          fontWeight: 700,
          paddingY: 1.5,
          my: 1,
        }}
      >
        ย้อนกลับ
      </Button>
    </Box>
  );
};

export default OCRScanPage;
